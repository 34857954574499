import React, { useEffect, useState } from "react";
import DynamicTrays from "../../Components/DynamicTrays";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addSelectedFilter,
  clearAllFilter,
  removeSelectedFilter,
  addSelectedPageNumber,
} from "../../redux-toolkit/slice/blogsFilter/blogsFilterSlice";
import RDSFilterList from "../../AceHomeServicesRds/src/components/rds-filters-list";
import { fetchCorporateBlogFilters } from "../../redux-toolkit/slice/corporateBlogFilterAPI/corporateBlogFilterSlice";
import ErrorPage from "../../Components/ErrorPage";
import { fetchCorporateBlogList } from "../../redux-toolkit/slice/corporateBlogFilterAPI/corporateBlogListSlice";
import { scrollToTopSmooth } from "../../Utilities/Utility";
import styles from "./blogs.module.css";
import RdsPagination from "../../AceHomeServicesRds/src/components/rds-pagination";
import { useNavigate } from "react-router-dom";
import useMetaDetails from "../../hooks/useMetaDetails";
import { BlogsData } from "../../constants/blogs";
import { Markup } from "interweave";

const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data = {},
    isError,
    statusCode,
  } = useSelector((state) => state.corporateBlogFilters);
  const { listData, isErrorList, statusCodeList } = useSelector(
    (state) => state.corporateBlogList
  );
  const [isPageContainsError, setIsPageContainsError] = useState(false);
  const selectedFilters = useSelector(
    (state) => state.blogFilter.selectedFilters
  );
  const selectedPageIndex = useSelector(
    (state) => state.blogFilter.selectedPageIndex
  );

  useMetaDetails(listData);

  useEffect(() => {
    const payload = {
      pageIndex: selectedPageIndex,
      corporateBlogFilterCriteria: {
        blogCategories: selectedFilters?.filter((item) => {
          if (isNaN(item)) {
            return item;
          }
        }),
        blogPublishedYears: selectedFilters?.filter((item) => {
          if (!isNaN(item)) {
            return item;
          }
        }),
      },
    };
    dispatch(fetchCorporateBlogList(payload));
  }, [selectedFilters, selectedPageIndex]);

  useEffect(() => {
    return () => {
      dispatch(clearAllFilter());
    };
  }, []);

  const handleFilterClick = (filter) => {
    dispatch(addSelectedFilter(filter));
  };

  const handleRemoveFilterClick = (filter) => {
    dispatch(removeSelectedFilter(filter));
  };

  const handleClearAll = () => {
    dispatch(clearAllFilter());
  };

  const handleRedirectFromBLP = (data) => {
    const { selectedCategory, redirectURL } = data;
    dispatch(clearAllFilter());
    dispatch(addSelectedFilter(selectedCategory));
    navigate(redirectURL);
  };

  /**
   * @function handleOnClickRedirectAction
   * @param {*} redirectUrl 
   */
  const handleOnClickRedirectAction = (redirectUrl) => {
    navigate(redirectUrl);
  };

  useEffect(() => {
    dispatch(fetchCorporateBlogFilters());
  }, []);

  useEffect(() => {
    if (isError) {
      setIsPageContainsError(isError);
    } else {
      setIsPageContainsError(isErrorList);
    }
  }, [isError, statusCode, statusCodeList, isErrorList]);

  /**
   * @function handlePageChange
   * @param {*} newPage
   */
  const handlePageChange = (newPage) => {
    dispatch(addSelectedPageNumber(newPage));
    scrollToTopSmooth();
  };

  return (
    <div className="container">
      {isPageContainsError ? (
        <ErrorPage
          statusCode={statusCode !== 200 ? statusCode : statusCodeList}
        />
      ) : (
        <div className={"row"}>
          <div className={"col-12 col-lg-8 pb-5"}>
            <h1 className={styles.heading}>{listData?.trays[0].heading}</h1>
            <p className={styles.subHeading}>
              <Markup content={listData?.trays[0].subHeading} />
            </p>
            <RDSFilterList
              selectedFilterList={selectedFilters}
              onClickSelectedFilter={handleRemoveFilterClick}
              onClickClearAll={handleClearAll}
            />
            <span className={styles.information}>
              showing {listData?.displayBlogsStartIndex} to{" "}
              {listData?.displayBlogsEndIndex} of {listData?.totalBlogsCount}{" "}
              blog posts
            </span>
            {listData?.trays?.map((tray, index) => {
              return (
                <DynamicTrays
                  {...tray}
                  key={index}
                  handleFilterClick={handleFilterClick}
                  handleRemoveFilterClick={handleRemoveFilterClick}
                  selectedFilters={selectedFilters}
                  handleRedirectFromBLP={handleRedirectFromBLP}
                  onClickRedirectAction={handleOnClickRedirectAction}
                />
              );
            })}

            {listData?.trays[0]?.blogPosts?.length > 0 && (
              <div className="text-center pt-4">
                <RdsPagination
                  currentPage={selectedPageIndex}
                  onPageChange={handlePageChange}
                  totalPages={listData?.totalPageCount}
                />
              </div>
            )}
            {listData?.trays[0]?.blogPosts?.length == 0 && (
              <div className="pt-5">
                <div className={styles.resultHeading}>{BlogsData.heading}</div>
                <div className={styles.resultSubHeading}>
                  {BlogsData.subHeading}
                </div>
              </div>
            )}
          </div>
          <div className={`col-12  col-lg-4 ${styles.containerColor}`}>
            {data?.trays?.map((tray, index) => {
              return (
                <DynamicTrays
                  {...tray}
                  key={index}
                  handleFilterClick={handleFilterClick}
                  handleRemoveFilterClick={handleRemoveFilterClick}
                  selectedFilters={selectedFilters}
                  handleRedirectFromBLP={handleRedirectFromBLP}
                  onClickRedirectAction={handleOnClickRedirectAction}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Blogs;
